















































































import { computed, defineComponent, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";

interface StepError {
  step: number;
  error: string;
}

export default defineComponent({
  components: {
    OPaymentAdd: () =>
      import(
        "@/components/organisms/globalTools/paymentMethod/o-payment-add.vue"
      ),
    OPaymentSummary: () =>
      import(
        "@/components/organisms/globalTools/paymentMethod/o-payment-summary.vue"
      ),
  },

  setup(_, { root }) {
    const model = reactive<{
      stepper: number;
      data: any;
    }>({
      stepper: 1,
      data: {
        type: "PayU",
        currency: "PLN",
      },
    });

    const dataSummary = computed(() => ({
      data: model.data,
    }));

    const state = reactive({
      loading: false,
      success: false,
      error: false,
      partnerPhoto: "",
      errors: computed((): StepError[] => {
        const errors: StepError[] = [];

        if (!model.data.name)
          errors.push({
            step: 1,
            error: root.$tc("panel.globalTools.payment.add.fillPaymentName"),
          });
        if (model.data.type === "PayU") {
          if (!model.data.clientId)
            errors.push({
              step: 1,
              error: root.$tc("panel.globalTools.payment.add.fillClientId"),
            });
          if (!model.data.clientSecret)
            errors.push({
              step: 1,
              error: root.$tc("panel.globalTools.payment.add.fillClientSecret"),
            });
          if (!model.data.merchantPosId)
            errors.push({
              step: 1,
              error: root.$tc("panel.globalTools.payment.add.fillPosId"),
            });
          if (!model.data.secondKey)
            errors.push({
              step: 1,
              error: root.$tc("panel.globalTools.payment.add.fillSecondKey"),
            });
        } else {
          if (!model.data.accountOwner)
            errors.push({
              step: 1,
              error: root.$tc("panel.globalTools.payment.add.fillAccountOwner"),
            });
          if (!model.data.bankName)
            errors.push({
              step: 1,
              error: root.$tc("panel.globalTools.payment.add.fillBankName"),
            });
          if (!model.data.accountNumber)
            errors.push({
              step: 1,
              error: root.$tc("panel.globalTools.payment.add.fillAcountNumber"),
            });
        }
        return errors;
      }),
    });

    const isStepEditable = () => {
      return true;
    };

    const isStepComplete = (step: number) => {
      return (
        model.stepper > step &&
        !state.errors.find((error) => error.step == step)
      );
    };

    const isStepValid = (step: number) => {
      return !state.errors.find((error) => error.step == step);
    };

    const isFormValid = computed(() => !state.errors.length);

    const goToErrors = () => {
      let lowest = Number.POSITIVE_INFINITY;
      let highest = Number.NEGATIVE_INFINITY;

      let tmp: number;

      for (let i = state.errors.length - 1; i >= 0; i--) {
        tmp = state.errors[i].step;
        if (tmp < lowest) lowest = tmp;
        if (tmp > highest) highest = tmp;
      }

      model.stepper = lowest;
    };

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        name: model.data.name,
        type: model.data.type || undefined,
        currency: model.data.currency || undefined,
        clientId: model.data.clientId || null,
        clientSecret: model.data.clientSecret || null,
        merchantPosId: model.data.merchantPosId || null,
        secondKey: model.data.secondKey || null,
        accountOwner: model.data.accountOwner || null,
        bankName: model.data.bankName || null,
        accountNumber: model.data.accountNumber || null,
      };

      state.loading = true;

      axiosInstance
        .post("payment-credentials", data)
        .then(() => {
          state.success = true;
          model.stepper = 1;
          model.data = {
            type: "PayU",
            currency: "PLN",
          };
        })
        .catch((error) => {
          state.error = error.response.status;
        })
        .finally(() => (state.loading = false));
    };

    return {
      model,
      state,
      isStepEditable,
      isStepComplete,
      isStepValid,
      isFormValid,
      goToErrors,
      dataSummary,
      onSubmit,
    };
  },
});
